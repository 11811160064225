import { configure } from "axios-hooks";
import LRU from "lru-cache";
import Axios from "axios";
import Settings from "./settings";

const { REACT_APP_USERINFO_DOMAIN, REACT_APP_USERINFO_TOKEN } = process.env;

const STAGE = "Prod";


export const setAxios = () => {
  const axios = Axios.create({
    //baseURL: `https://${REACT_APP_USERINFO_DOMAIN}/${STAGE}`,
    baseURL: `https://${Settings.user_info_api_domain}/${STAGE}`,
    withCredentials: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      //authorizationToken: REACT_APP_USERINFO_TOKEN,
      authorizationToken: Settings.user_info_api_token,
    },
  });

  const cache = new LRU({ max: 10 });

  configure({ axios, cache });
};

const config = {
  messagePath: "/message",
  user: "/userinfo",
};

export default config;
