/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_KUENKRR3Y",
    "aws_user_pools_web_client_id": "638ukile4b7qqvv9gth1j9f6mf",
    "oauth": {
        "scope": [
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://example.com/webportal",
        "redirectSignOut": "https://example.com/webportal",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
