import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as RouterProvider } from "react-router-dom";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import Login from "@chambercustoms/common-components/src/components/Login/Login";
import chamberCustomsLogo from "@chambercustoms/common-components/src/assets/chamber-customs-logo.png";
import { DrawerMenuContextProvider } from "@chambercustoms/common-components/src/components/DrawerMenu/useDrawerMenu";
import { DrawerProfileContextProvider } from "@chambercustoms/common-components/src/components/DrawerProfile/useDrawerProfile";
import Main from "./Main";

import awsExports from "./aws-exports";
import theme from "./theme";

import "./App.css";
import { UserContextProvider } from "./hooks/useUserInfo";

Amplify.configure(awsExports);

const App = () => {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<object | undefined>();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  if (authState === AuthState.SignedIn && user) {
    return (
      <AmplifyAuthenticator>
        <RouterProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<div>Page is Loading...</div>}>
              <SnackbarProvider maxSnack={3}>
                <UserContextProvider>
                  <DrawerProfileContextProvider>
                    <DrawerMenuContextProvider>
                      <Main />
                      <a href="https://chambercustoms.freshstatus.io" id="freshstatus-badge-root"
  data-banner-style="highlighted">
  <img src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=419b43da-3b87-4a46-9a87-bbbe4dbf7584" />
    </a>
                    </DrawerMenuContextProvider>
                  </DrawerProfileContextProvider>
                </UserContextProvider>
              </SnackbarProvider>
            </Suspense>
          </ThemeProvider>
        </RouterProvider>

      </AmplifyAuthenticator>
    );
  }

  return <Login logoUrl={chamberCustomsLogo} logoAlt="ChamberCustoms" />;
};

export default App;
