import { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import MainLayout from "@chambercustoms/common-components/src/components/MainLayout/MainLayout";
import { MenuProps } from "@chambercustoms/common-components/src/components/Menu/Menu";
import { BottomMenuProps } from "@chambercustoms/common-components/src/components/BottomMenu/BottomMenu";
import { HeaderProps } from "@chambercustoms/common-components/src/components/Header/Header";
import chamberCustomsLogo from "@chambercustoms/common-components/src/assets/chamber-customs-logo.png";
import useDrawerProfile from "@chambercustoms/common-components/src/components/DrawerProfile/useDrawerProfile";
import useUser from "./hooks/useUserInfo";

const Home = lazy(() => import("./pages/Home/Home"));
const ClientErrors = lazy(() => import("./pages/ClientErrors/ClientErrors"));

const Main = () => {
  const { cognitoUser, loading } = useUser();
  const [isDrawerProfileOpen, setIsDrawerProfileOpen] = useDrawerProfile();

  const onProfileClickHandler = () =>
    setIsDrawerProfileOpen(!isDrawerProfileOpen);

  const header = {
    userName: cognitoUser?.attributes?.email,
    title: "CHAMBER CUSTOMS",
    logoUrl: chamberCustomsLogo,
    onProfileClick: onProfileClickHandler,
  } as HeaderProps;

  const menuProfile = {
    items: [
      {
        label: "Logout",
        icon: <LogoutIcon />,
        onClick: async () => {
          await Auth.signOut();
          window.location.reload();
        },
      },
    ],
  } as MenuProps;

  const bottomMenu = {
    onProfileClick: onProfileClickHandler,
  } as BottomMenuProps;

  if (loading) {
    return (
      <Typography textAlign="center" component="p">
        Loading...
      </Typography>
    );
  }

  return (
    <MainLayout
      bottomMenuProps={bottomMenu}
      headerProps={header}
      menuProfileProps={menuProfile}
    >
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/403">
          <ClientErrors title="403" subTitle="Forbidden" />
        </Route>
        <Route path="*">
          <ClientErrors title="404" subTitle="Not found" />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default Main;
