import { FC, createContext, useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import config from "../config";

export type UserContextType = {
  user: Record<string, any> | undefined;
  cognitoUser: CognitoUserInterface | undefined;
  loading: boolean | undefined;
  setUser: (value: UserContextType["user"]) => void;
  setCognitoUser: (value: UserContextType["cognitoUser"]) => void;
};

export type UserContextProviderProps = {};

export const UserContext = createContext<UserContextType>({
  user: undefined,
  cognitoUser: undefined,
  loading: undefined,
  setUser: (value) => value,
  setCognitoUser: (value) => value,
});

export const UserContextProvider: FC<UserContextProviderProps> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<UserContextType["user"]>();
  const [cognitoUser, setCognitoUser] = useState<
    UserContextType["cognitoUser"]
  >();

  useEffect(() => {
    (async () => {
      const cognitoUserRes = (await Auth.currentAuthenticatedUser()) as CognitoUserInterface;
      setCognitoUser(cognitoUserRes);
    })();
  }, []);

  const [{ data, error, loading }, userRequest] = useAxios(
    {
      url: config.user,
    },
    { manual: true }
  );

  const email = cognitoUser?.attributes?.email;

  useEffect(() => {
    (async () => {
      if (email) {
        await userRequest({
          params: {
            user_name: email,
          },
        });
      }
    })();
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    if (data) setUser(data);
  }, [data]);

  if (error) {
    enqueueSnackbar(error?.message, { variant: "error" });
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, cognitoUser, setCognitoUser, loading }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export default useUser;
